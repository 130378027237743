import { Box, chakra } from "@chakra-ui/react";
import { StylesConfig } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import SimpleBar from "simplebar-react";
import { ChevronIcon } from "../../svg";

const StyledSimplebar = chakra<typeof SimpleBar, { isInvalid: boolean; value?: any }>(SimpleBar, {
  baseStyle: ({ value, isInvalid }: any) => ({
    width: "100%",
    maxHeight: "100px",
    borderRadius: "0 0 16px 16px",
    borderColor: isInvalid ? "accent.red" : value ? "text.heading" : "text.gray",
    borderWidth: "0 1px 1px 1px",
    background: "white",

    ".simplebar-content": {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      marginTop: "-10px",
    },
  }),
});

export const styles = (isInvalid: boolean): StylesConfig<any, false, any> => ({
  container: (baseStyles) => ({
    ...baseStyles,
    width: "100%",
    height: "52px",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    "&:hover": { borderColor: "none" },
    borderRadius: state.menuIsOpen ? "16px 16px 0 0" : "16px",
    borderWidth: state.menuIsOpen ? "1px 1px 0 1px" : "1px",
    borderColor: isInvalid ? "#D82853" : state.hasValue ? "#1B1B1B" : "#B7B7B7",
    padding: "0 16px",
    boxShadow: "none",
    height: "100%",
    width: "100%",
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: state.selectProps.menuIsOpen ? "14px" : "16px",
    color: "595959",
    padding: 0,
  }),
  menu: (baseStyle) => ({
    ...baseStyle,
    bottom: 0,
    marginTop: 0,
    boxShadow: "none",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: isInvalid ? "#D82853" : "#B7B7B7",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    background: state.selectProps.menuIsOpen ? "red" : "white",
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    margin: "0 16px",
    padding: "0px",
    borderBottomWidth: "0.2px",
    borderBottomColor: "#B7B7B7",
    fontSize: "16px",
    width: "calc(100% - 64px)",
    background: "white",
    color: "#595959",
  }),
});

const Container = chakra<typeof Box, { value?: any; isInvalid: boolean }>(Box, {
  baseStyle: ({ isInvalid, value }: any) => ({
    "& > svg > path": {
      stroke: isInvalid ? "accent.red" : value ? "text.heading" : "text.gray",
    },
  }),
});

export const components = (isInvalid: boolean): Partial<SelectComponents<any, false, any>> => ({
  DropdownIndicator: ({ hasValue, selectProps }) =>
    selectProps.menuIsOpen ? null : (
      <Container isInvalid={isInvalid} value={hasValue}>
        <ChevronIcon />
      </Container>
    ),
  MenuList: ({ children, hasValue }) => (
    <StyledSimplebar isInvalid={isInvalid} value={hasValue}>
      {children}
    </StyledSimplebar>
  ),
});
