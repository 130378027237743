import { Link, Text } from "@chakra-ui/react";
import { FormSchema } from "../FormBuilder/FormBuilder.types";
import { createFirstElements, createThirdElements, secondFormElements } from "./FormSteps";
import { CreateFormProps } from "./ReviewForm.types";

export const createForm = ({
  index,
  isPlatformVisible,
  isBanksVisible,
  forms: { firstForm, secondForm, thirdForm },
}: CreateFormProps): FormSchema => {
  const form: FormSchema[] = [
    {
      header: "Заполните все данные в анкете и отправьте свой отзыв на проверку",
      watchName: "city",
      elements: createFirstElements(isPlatformVisible),
      form: firstForm,
      position: "static",
    },
    {
      header: "Добавьте скриншот отзыва",
      description: (
        <Text fontSize="14px" color="text.heading">
          Убедитесь, что на скриншоте четко отображаются данные: никнейм, название филиала, оценка,
          комментарий, дата и статус отзыва.{" "}
          <Link href="/" color="accent.red">
            Посмотреть пример отзыва
          </Link>
        </Text>
      ),
      elements: secondFormElements,
      form: secondForm,
    },
    {
      header: "Выберите удобный для вас способ получения вознаграждения",
      watchName: "refill",
      elements: createThirdElements(isBanksVisible),
      form: thirdForm,
    },
  ];

  return form[index];
};
