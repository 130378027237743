import { Button, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { FieldValues, FormProvider, SubmitErrorHandler } from "react-hook-form";
import { Stepper } from "../../common";
import { Builder } from "./Builder/Builder";
import { Header } from "./FormBuilder.styles";
import { FormBuilderProps } from "./FormBuilder.types";

export const FormBuilder = ({
  onUpdateWatchName,
  activeStepIndex,
  description,
  watchName,
  elements,
  onSubmit,
  header,
  form,
  ...props
}: FormBuilderProps) => {
  const {
    formState: { isDirty, isValid },
    handleSubmit,
  } = form;

  const variant = !isDirty || !isValid ? "secondary" : "primary";

  const onError: SubmitErrorHandler<FieldValues> = (e) => console.log("validation_error", e);

  console.log(form.watch());

  useEffect(() => {
    const fieldValue = form.getValues(watchName);

    if (fieldValue) {
      onUpdateWatchName?.(watchName, fieldValue);
    }
  }, [form.watch(watchName)]);

  return (
    <FormProvider {...form}>
      <Stepper activeStepIndex={activeStepIndex} {...props} />
      <VStack gap="12px" w="full" alignItems="start">
        <Header noOfLines={3}>{header}</Header>
        {description}
      </VStack>
      <Builder elements={elements} />
      <Button onClick={handleSubmit(onSubmit, onError)} variant={variant}>
        {activeStepIndex === props.steps ? "Отправить" : "Далее"}
      </Button>
    </FormProvider>
  );
};
