import { inputAnatomy } from '@chakra-ui/anatomy';
import { chakra, createMultiStyleConfigHelpers, Input } from '@chakra-ui/react';
import { ReactNode } from 'react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const light = definePartsStyle({
    field: {
        height: "52px",
        borderRadius: "16px",
        borderWidth: "1px",
        borderColor: "text.gray",
        paddingX: "20px",

        _placeholder: {
            color: "text.gray",
            fontSize: "16px"
        },
    },
})

export const inputTheme = defineMultiStyleConfig({ variants: { light }, defaultProps: { variant: 'light' } });

export const StyledInput = chakra<typeof Input, { leftContent: ReactNode, hasError?: boolean, value?: any }>(Input, {
    baseStyle: ({ leftContent, value, hasError }: any) => ({
        paddingInlineStart: leftContent ? "40px" : "20px",
        borderColor: hasError ? 'accent.red' : value ? "text.heading" : "text.gray",
        color: hasError ? 'accent.red' : 'text.body',

        _placeholder: {
            color: hasError ? 'accent.red' : 'text.gray'
        },

        _focusVisible: {
            borderColor: hasError ? 'accent.red' : "text.heading"
        }
    }),
});