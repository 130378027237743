import { InputGroup, InputLeftElement } from "@chakra-ui/react";
import { InputWrapper } from "../../wrappers";
import { StyledInput as Input } from "./CommonInput.styles";
import { CommonInputProps } from "./CommonInput.types";

export const CommonInput = ({
  errorMesssage,
  isRequired,
  label,
  leftContent,
  ...props
}: CommonInputProps) => (
  <InputWrapper label={label} isRequired={isRequired} errorMesssage={errorMesssage}>
    <InputGroup>
      {leftContent ? <InputLeftElement pointerEvents="none">{leftContent}</InputLeftElement> : null}
      <Input leftContent={leftContent} hasError={Boolean(errorMesssage)} {...props} />
    </InputGroup>
  </InputWrapper>
);
