import { Controller } from "react-hook-form";
import { ConnectForm } from "../../providers";
import { Checkbox } from "./Checkbox";
import { ControlledCheckboxProps } from "./Checkbox.types";

export const ControlledCheckbox = ({ name, ...props }: ControlledCheckboxProps) => (
  <ConnectForm>
    {({ control }) => (
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox {...props} isChecked={value} onChange={onChange} />
        )}
      />
    )}
  </ConnectForm>
);
