import { chakra, VStack } from "@chakra-ui/react";

export const Container = chakra(VStack, {
    baseStyle: {
        gap: '14px',
        paddingY: "10px",
        alignItems: 'start',
        width: 'calc(100% - 32px)',
        left: '16px',
        bottom: '0'
    }
})