import { Box, chakra } from "@chakra-ui/react";

export const Container = chakra(Box, {
    baseStyle: {
        width: "152px",
        height: "152px",
        borderRadius: "16px",
        backgroundColor: "image.gray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
})