import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useToggle } from "usehooks-ts";
import { FormSchema } from "../FormBuilder/FormBuilder.types";
import { FirstFormSchema, SecondFormSchema, ThirdFormSchema } from "./FormSteps";
import { getAddress, uploadForm } from "./ReviewForm.api";
import {
    BANK_FIELD_NAME,
    CITY_FIELD_NAME,
    CITY_POINT_FIELD_NAME,
    REFILL_FIELD_NAME,
    SECOND_STEP_INDEX,
    STEPS_NUMBER,
} from "./ReviewForm.constants";
import { createForm } from "./ReviewForm.data";
import { ReviewForm, UseFormDataReturn } from "./ReviewForm.types";
import { getFormData, populateOptions, setFieldVisible } from "./ReviewForm.utils";

const useFormData = (): UseFormDataReturn => {
    const firstForm = useForm({
        resolver: zodResolver(FirstFormSchema),
        mode: "onBlur"
    })

    const secondForm = useForm({
        resolver: zodResolver(SecondFormSchema),
        mode: "onBlur"
    })

    const thirdForm = useForm({
        resolver: zodResolver(ThirdFormSchema),
        mode: "onBlur"
    })

    return { firstForm, secondForm, thirdForm }
}

export const useReviewForm = () => {
    const [activeStepIndex, setActiveStep] = useState<number>(0);
    const [isPlatformVisible, togglePlatformVisible] = useToggle(false);
    const [isBanksVisible, , setIsBankVisible] = useToggle(false);
    const [formData, setFormData] = useState<object>();

    const forms: UseFormDataReturn = useFormData();

    const [activeForm, setActiveForm] = useState<FormSchema>(
        createForm({ index: activeStepIndex, isPlatformVisible, isBanksVisible, forms })
    );

    const [formSubmitState, setFormSubmitState] = useState<boolean | undefined>(undefined);

    const handleBack = () => setActiveStep(activeStepIndex - 1);

    const handleSubmit = async (fieldValues: FieldValues) => {
        if (activeStepIndex === STEPS_NUMBER - 1) {
            const isFormSuccess = await uploadForm({ ...formData, ...fieldValues } as ReviewForm);

            setFormSubmitState(isFormSuccess);
        } else {
            setFormData({ ...formData, ...fieldValues });
            setActiveStep(activeStepIndex + 1);
        }
    };

    const handleYes = () => {
        setFormSubmitState(undefined);
        setActiveStep(0);
    };

    const handleUpdateWatchName = async (key: string, fieldValue: any) => {
        if (key === CITY_FIELD_NAME && fieldValue) {
            if (!isPlatformVisible) {
                togglePlatformVisible();
            }

            getAddress(fieldValue.value)
                .then((options) => populateOptions(activeForm, options, CITY_POINT_FIELD_NAME))
                .then((form) => setFieldVisible(form, CITY_POINT_FIELD_NAME, true))
                .then(setActiveForm);
        } else if (key === REFILL_FIELD_NAME) {
            const isVisible = fieldValue.value === "СБП";

            setActiveForm(setFieldVisible(activeForm, BANK_FIELD_NAME, isVisible));
            setIsBankVisible(isVisible);
        }
    };

    useEffect(() => {
        const activeForm = createForm({ index: activeStepIndex, isPlatformVisible, isBanksVisible, forms });

        setActiveForm(activeForm);

        if (activeStepIndex !== SECOND_STEP_INDEX) {
            getFormData(activeStepIndex)
                .then((data) =>
                    data.reduce((acc, { name, options }) => {
                        acc = populateOptions(acc, options, name);

                        return acc;
                    }, activeForm)
                )
                .then(setActiveForm);
        }
    }, [activeStepIndex]);

    return {
        states: { formSubmitState, activeForm, activeStepIndex },
        actions: { handleBack, handleSubmit, handleYes, handleUpdateWatchName },
    };
};
