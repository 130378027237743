import { api } from "../../../api";
import { SelectOption } from "../../../types";
import { cities, replenishmentTypes } from "./ReviewForm.constants";
import { FieldData, ReviewForm } from "./ReviewForm.types";

const getAddress = async (cityName: string | undefined): Promise<SelectOption[]> => {
    const places = await api.get('/address', { params: { name: cityName } }).then(response => response.data);
    const currentPlace = places.find((place: any) => place.name === cityName);

    return currentPlace?.addresses.map((address: any) => ({ label: address.address_name, value: address.id }));
}

const getPlatforms = async (): Promise<SelectOption[]> => {
    return api.get<SelectOption[]>('/platforms')
        .then(response => response.data)
        .then((data: any[]) => data.map(({ title, id }) => ({ label: title, value: id })))
}

const getBanks = async (): Promise<SelectOption[]> => {
    return api.get<SelectOption[]>('/banks')
        .then(response => response.data)
        .then((data: any[]) => data.map(({ title, id }) => ({ label: title, value: id })))
}

const uploadForm = async ({ cityPoint, app, bank, name, screenshot, phone, refill }: ReviewForm) => api.postForm('/send-lead', {
    phone: `+7${phone.replace(/[()_ -]/g, '')}`,
    address_id: cityPoint.value,
    source_id: app.value,
    bank_id: refill.value === "СБП" ? bank?.value : undefined,
    name, image: screenshot
})
    .then(() => true)
    .catch(() => false);

const getFirstStepData = async (): Promise<FieldData[]> => {
    const platformList = await getPlatforms();

    return [{ options: cities, name: 'city' }, { options: platformList, name: "app" }];
}

const getThridStepData = async (): Promise<FieldData[]> => {
    const banks = await getBanks();

    return [{ name: "bank", options: banks }, { name: "refill", options: replenishmentTypes }]
}

export { getAddress, getFirstStepData, getThridStepData, uploadForm };
