export const EmptyPhotoIcon = () => (
  <svg width="96" height="56" viewBox="0 0 96 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="96" height="56" rx="18" fill="#C9C9C9" />
    <path
      d="M27.8132 31.2007C29.4139 29.0908 32.5861 29.0908 34.1868 31.2007L49.3486 51.1868C50.8474 53.1624 49.4383 56 46.9586 56H9L27.8132 31.2007Z"
      fill="white"
    />
    <path
      d="M57.807 23.1166C59.432 20.9259 62.7248 20.9684 64.2927 23.2003L87.3335 55.9998H75.5078H33.4155L57.807 23.1166Z"
      fill="white"
    />
    <ellipse cx="17.8615" cy="13.96" rx="8.86154" ry="8.96" fill="white" />
  </svg>
);
