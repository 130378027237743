import { Link } from "@chakra-ui/react";
import { SelectOption } from "../../../../../types";
import { DynamicFormElement } from "../../../FormBuilder/FormBuilder.types";

export const cities: SelectOption[] = [{ label: "Москва", value: "Москва" }];

export const createFirstElements = (isPlatformVisible: boolean = false): DynamicFormElement[] => [
  {
    type: "Input",
    name: "name",
    placeholder: "Имя",
    label: "Введите имя",
    isRequired: true,
  },
  {
    type: "Select",
    name: "city",
    placeholder: "Город",
    label: "Укажите город",
    isRequired: true,
    options: cities,
  },
  {
    type: "Select",
    name: "cityPoint",
    placeholder: "Выберите филиал",
    label: "Выберите филиал",
    isRequired: true,
    visible: isPlatformVisible,
    options: cities,
  },
  {
    type: "Select",
    name: "app",
    placeholder: "Выберите площадку",
    label: "Выберите площадку",
    isRequired: true,
    options: cities,
  },
  {
    type: "Checkbox",
    name: "agree",
    children: (
      <>
        Я согласен на{" "}
        <Link href="/" color="accent.red">
          обработку персональных данных
        </Link>
      </>
    ),
  },
];
