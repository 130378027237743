import { Controller } from "react-hook-form";
import { ConnectForm } from "../../providers";
import { CommonSelect } from "./CommonSelect";
import { ControlledCommonSelectProps } from "./CommonSelect.types";

export const ControlledSelect = ({ visible = true, name, ...props }: ControlledCommonSelectProps) =>
  visible ? (
    <ConnectForm>
      {({ control }) => (
        <Controller
          key={name}
          name={name}
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <CommonSelect
              errorMesssage={errors[name]?.message?.toString()}
              onChange={onChange}
              value={value}
              {...props}
            />
          )}
        />
      )}
    </ConnectForm>
  ) : null;
