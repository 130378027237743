import { ControlledComponent } from "../../../../types";
import {
  ControlledCheckboxProps,
  ControlledCommonSelectProps,
  ControlledInputProps,
  ControlledPhoneInputProps,
} from "../../../common";
import { ControlledCheckbox } from "../../../common/Checkbox/ControlledCheckbox";
import { ControlledInput } from "../../../common/CommonInput/ControlledInput";
import { ControlledSelect } from "../../../common/CommonSelect/ControlledSelect";
import { ControlledImagePicker } from "../../../common/ImagePicker/ControlledImagePicker";
import { ControlledPhoneInput } from "../../../common/PhoneInput/ControlledPhoneInput";
import { FormElementType, Schema } from "../FormBuilder.types";

export const BuilderSchema: Record<FormElementType, Schema[FormElementType]> = {
  Input: (props: ControlledInputProps) => <ControlledInput {...props} />,
  Select: (props: ControlledCommonSelectProps) => <ControlledSelect {...props} />,
  Checkbox: (props: ControlledCheckboxProps) => <ControlledCheckbox {...props} />,
  Phone: (props: ControlledPhoneInputProps) => <ControlledPhoneInput {...props} />,
  ImagePicker: (props: ControlledComponent) => <ControlledImagePicker {...props} />,
};
