import { Controller } from "react-hook-form";
import { ControlledComponent } from "../../../types";
import { ConnectForm } from "../../providers";
import { ImagePicker } from "./ImagePicker";

export const ControlledImagePicker = ({ name }: ControlledComponent) => (
  <ConnectForm>
    {({ control }) => (
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <ImagePicker
            errorMesssage={errors[name]?.message?.toString()}
            value={value ? URL.createObjectURL(value) : null}
            onChange={onChange}
          />
        )}
      />
    )}
  </ConnectForm>
);
