import { InputWrapper } from "../../wrappers";
import { CommonSelectProps } from "./CommonSelect.types";

import Select from "react-select";
import "simplebar-react/dist/simplebar.min.css";
import { components, styles } from "./CommonSelect.styles";

export const CommonSelect = ({
  placeholder,
  onChange,
  options,
  value,
  ...props
}: CommonSelectProps) => (
  <InputWrapper {...props}>
    <Select
      components={components(Boolean(props.errorMesssage))}
      styles={styles(Boolean(props.errorMesssage))}
      placeholder={placeholder}
      onChange={onChange}
      isSearchable={false}
      options={options}
      value={value}
    />
  </InputWrapper>
);
