import { Checkbox as ChakraCheckbox, CheckboxProps } from "@chakra-ui/react";
import { CheckIcon } from "../../svg";

export const Checkbox = ({ onChange, isChecked, children }: CheckboxProps) => (
  <ChakraCheckbox
    icon={isChecked ? <CheckIcon /> : <></>}
    isChecked={isChecked}
    onChange={onChange}
  >
    {children}
  </ChakraCheckbox>
);
