import { Step } from "./Step/Step";
import { FIRST_STEP_INDEX } from "./Stepper.constants";
import { CancelButton, Container, Flex, Navigation, Steps } from "./Stepper.styles";
import { StepperProps } from "./Stepper.types";

export const Stepper = ({ activeStepIndex, onBack, steps }: StepperProps) => (
  <Container>
    <Navigation isFirstStep={activeStepIndex === FIRST_STEP_INDEX}>
      {activeStepIndex !== FIRST_STEP_INDEX ? (
        <CancelButton onClick={onBack}>Отменить</CancelButton>
      ) : null}
      <Steps>{`Шаг ${activeStepIndex + 1} из ${steps}`}</Steps>
    </Navigation>
    <Flex>
      {Array.from({ length: steps }, (_, i) => i).map((index) => (
        <Step isActive={index === activeStepIndex} key={index} />
      ))}
    </Flex>
  </Container>
);
