import { Box, chakra } from "@chakra-ui/react";

const Container = chakra(Box, {
    baseStyle: {
        position: "relative",
        width: "100%",
        height: "4px"
    }
})

const Indicator = chakra<typeof Box, { isActive?: boolean }>(Box, {
    baseStyle: ({ isActive }: any) => ({
        width: isActive ? "70%" : "100%",
        opacity: isActive ? '1' : '0.2',
        background: "accent.red",
        borderRadius: "4px",
        height: "100%",
    })
})

export { Container, Indicator };
