import { Header } from "../components/common";
import { ReviewForm } from "../components/forms";

import Logo from "./logo.png";

export const FormScreen = () => (
  <>
    <Header logo={Logo} phone="+7(910) 873-37-36" />
    <ReviewForm />
  </>
);
