import { Image } from "@chakra-ui/react";
import { Container, Link, LogoWrapper } from "./Header.styles";
import { HeaderProps } from "./Header.types";

export const Header = ({ logo, phone }: HeaderProps) => (
    <Container>
        <LogoWrapper>
            <Image src={logo} />
        </LogoWrapper>
        <Link>{phone}</Link>
    </Container>
)