import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  width: "100%",
  minHeight: "54px",
  borderRadius: "16px",
  fontWeight: "semibold"
});

const secondary = defineStyle({
  ...baseStyle,
  borderWidth: "1px",
  borderColor: "accent.red",
  color: "accent.red",

  _disabled: {
    opacity : 1
  }
});

const primary = defineStyle({
  ...baseStyle,
  background: "linear-gradient(85deg, #970E5F 0%, #DA2852 100%)",
  color: "white",
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: { secondary, primary },
  defaultProps: { variant: "primary" },
});
