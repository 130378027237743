import { Box, chakra } from "@chakra-ui/react";

export const Button = chakra<typeof Box, { hasError: boolean }>(Box, {
    baseStyle: ({ hasError }: any) => ({
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: hasError ? 'accent.red' : "image.gray"
    })
})