import { chakra, Text } from "@chakra-ui/react";

const Header = chakra(Text, {
    baseStyle: {
        fontSize: "24px",
        fontWeight: 'semibold',
        color: 'text.heading',
        lineHeight: "2rem"
    }
})

const Form = chakra('form');

export { Form, Header };

