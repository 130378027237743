import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { FormScreen } from './screens/FormScreen';

const App = () => (
  <Router>
    <Routes>
      <Route path="*" element={<FormScreen />} />
    </Routes>
  </Router>
);

export default App;
