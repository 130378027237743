import { chakra, VStack } from "@chakra-ui/react";

const MainContainer = chakra(VStack, {
    baseStyle: {
        boxShadow: "card",
        background: 'background.white',
        flexGrow: "1",
        borderRadius: "16px 16px 0 0",
        width: "100%",
        position: 'relative',
        alignItems: 'start',
        justifyContent: 'space-between',
        padding: '16px',
        height: '100%',
    }
})

const Container = chakra(VStack, {
    baseStyle: {
        width: "100%",
        height: "100%",
        flexGrow: "1",
        alignItems: 'start',
        gap: '16px'
    }
})

export { Container, MainContainer };
