import { Box, chakra } from "@chakra-ui/react";
import { CloseButton } from "../CloseButton/CloseButton";

const ErrorOverlay = chakra(Box, {
    baseStyle: {
        background: "accent.red",
        opacity: "30%",
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 2
    }
})

const RemoveButton = chakra(CloseButton, {
    baseStyle: {
        position: "absolute",
        top: '5px',
        right: "5px",
        zIndex: 3
    }
})

export { ErrorOverlay, RemoveButton };
