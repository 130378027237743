import { Container, ErrorMessage, Label } from "./InputWrapper.styles";
import { InputWrapperProps } from "./InputWrapper.types";
import { createErrorMessage } from "./InputWrapper.utils";

export const InputWrapper = ({ label, isRequired, children, errorMesssage }: InputWrapperProps) => (
  <Container>
    {label ? <Label isRequired={isRequired}>{label}</Label> : null}
    {children}
    {errorMesssage ? <ErrorMessage isRequired={isRequired}>{createErrorMessage(errorMesssage)}</ErrorMessage> : null}
  </Container>
);
