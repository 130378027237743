import { chakra, Text, VStack } from "@chakra-ui/react";

const Container = chakra(VStack, {
    baseStyle: {
        alignItems: "start",
        width: "100%",
        gap: "5px"
    }
})

const Label = chakra<typeof Text, { isRequired?: boolean }>(Text, {
    baseStyle: ({ isRequired }: any) => ({
        height: "26px",
        fontSize: "14px",
        color: "text.body",

        _after: {
            content: isRequired ? '"*"' : '""',
            color: 'text.body'
        }
    })
})

const ErrorMessage = chakra<typeof Text, { isRequired?: boolean }>(Text, {
    baseStyle: ({ isRequired }: any) => ({
        fontSize: "12px",
        color: "accent.red",

        _before: {
            content: isRequired ? '"* "' : '""',
            color: 'accent.red'
        }
    })
})


export { Container, ErrorMessage, Label };

