import Tailwind from "primereact/passthrough/tailwind";
import {twMerge} from "tailwind-merge";

import {APIOptions, PrimeReactProvider as InternalPrimeReactProvider} from "primereact/api";
import { PropsWithChildren } from "react";

const primeReactConfig: Partial<APIOptions> = {
    unstyled: false,
    pt: Tailwind,
    ptOptions: {
        mergeSections: true,
        mergeProps: true,
        classNameMergeFunction: twMerge
    }
}

export const PrimeReactProvider = ({children} : PropsWithChildren) => (
    <InternalPrimeReactProvider value={primeReactConfig}>
        {children}
    </InternalPrimeReactProvider>
);