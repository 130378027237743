import { SelectOption } from "../../../types";
import { FormSchema } from "../FormBuilder/FormBuilder.types";
import { getFirstStepData, getThridStepData } from "./ReviewForm.api";
import { FieldData } from "./ReviewForm.types";

export const populateOptions = (form: FormSchema, options: SelectOption[], name: string): FormSchema => {
    const elementsWithOptions = form.elements.map((element) => {
        if (element.name === name) {
            return { ...element, options: options };
        }

        return element;
    });

    return { ...form, elements: elementsWithOptions }
}

export const setFieldVisible = (form: FormSchema, name: string, visible: boolean): FormSchema => ({
    ...form,
    elements: form.elements.map((element) => {
        if (element.name === name) {
            return { ...element, visible: visible }
        }

        return element;
    })
})

export const getFormData = async (activeStepIndex: number): Promise<FieldData[]> => {
    if (activeStepIndex === 0) {
        return getFirstStepData();
    }

    return getThridStepData();
}