import { Box, chakra, HStack } from "@chakra-ui/react"

const Container = chakra(HStack, {
    baseStyle: {
        boxShadow: "card",
        justifyContent: "space-between",
        background: 'background.white',
        flexShrink: 0,
        alignItems: "center",
        borderRadius: "16px",
        paddingX: '10px',
        width: "100%",
        height: "64px",
    }
})

const LogoWrapper = chakra(Box, {
    baseStyle: {
        height: '100%',
        width: 'fit-content',
        alignItems: "center",
        display: "flex",
    }
})

const Link = chakra('a', {
    baseStyle: {
        fontSize: "14px",
        color: 'text.body'
    }
})

export { Container, Link, LogoWrapper }
