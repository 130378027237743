import { chakra, HStack, Text, VStack } from "@chakra-ui/react";

const Container = chakra(VStack, {
    baseStyle: {
        alignItems: "start",
        width: "100%",
        gap: "14px"
    }
})

const Navigation = chakra<typeof HStack, { isFirstStep: boolean }>(HStack, {
    baseStyle: ({ isFirstStep }: any) => ({
        justifyContent: isFirstStep ? "end" : "space-between",
        alignItems: "baseline",
        width: "100%",
    })
})

const CancelButton = chakra('button', {
    baseStyle: {
        background: 'none',
        color: "text.gray",
        fontSize: "12px",
        fontWeight: "400",
        padding: "0",
        outline: "0"
    }
})

const Steps = chakra(Text, {
    baseStyle: {
        fontSize: "16px",
        color: "accent.red"
    }
})

const Flex = chakra(HStack, {
    baseStyle: {
        width: "100%",
        gap: "10px"
    }
})

export { CancelButton, Container, Flex, Navigation, Steps };

