import { Button, HStack, Image, Link, Text, VStack } from "@chakra-ui/react";

import SuccessEmoji from "../../../../../assets/SuccessEmoji.png";
import { NO_LINK, YES_LINK } from "../../ReviewForm.constants";

export const FormSuccess = () => (
  <VStack gap="30px">
    <VStack w="full" gap="11px">
      <Text fontSize="20px" color="text.heading">
        Отзыв отправлен на проверку
      </Text>
      <Text fontSize="16px" color="text.body" noOfLines={4} textAlign="center">
        Спасибо за Ваши положительные впечатления! Выплата будет осуществлена в течение суток после
        проверки.
      </Text>
    </VStack>
    <Image src={SuccessEmoji} width="175px" height="175px" />
    <VStack w="full" gap="16px">
      <Text fontSize="20px" fontWeight="semibold" color="accent.red" textAlign="center">
        Хотите отправить еще один отзыв на проверку?
      </Text>
      <HStack w="225px" gap="12px">
        <Link href={YES_LINK} w="full">
          <Button>Да</Button>
        </Link>
        <Link href={NO_LINK} w="full">
          <Button variant="secondary">Нет</Button>
        </Link>
      </HStack>
    </VStack>
  </VStack>
);
