import { ChakraProvider } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { theme } from "../../theme";
import { PrimeReactProvider } from "./PrimeReactProvider";

export const Provider = ({ children }: PropsWithChildren) => (
    <PrimeReactProvider>
        <ChakraProvider theme={theme}>
            {children}
        </ChakraProvider>
    </PrimeReactProvider>
)