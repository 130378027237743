import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
    label: {
        fontSize: '16px',
        color: 'text.body',
        marginLeft: '12px',
        noOfLines: 2
    },
    control: {
        width: '20px',
        height: '20px',
        borderRadius: 5,
        borderWidth: "0.8px",
        borderColor: 'text.gray',

        _checked: {
            borderColor: 'accent.red',
            background: 'transparent',

            _hover: {
                borderColor: 'accent.red',
                background: 'transparent'
            }
        },
    },
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })