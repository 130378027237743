import { Text } from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { CommonInput } from "../CommonInput/CommonInput";
import { CommonInputProps } from "../CommonInput/CommonInput.types";
import { INPUT_MASK, PLACEHOLDER } from "./PhoneInput.constants";

export const PhoneInput = ({ onChange, value, ...props }: CommonInputProps) => (
  <InputMask onChange={onChange} value={value} mask={INPUT_MASK}>
    {(inputProps) => (
      <CommonInput
        {...props}
        {...inputProps}
        leftContent={<Text color={props.errorMesssage ? "accent.red" : "text.body"}>+7</Text>}
        placeholder={PLACEHOLDER}
        disableUnderline
        type="tel"
      />
    )}
  </InputMask>
);
