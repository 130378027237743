import { Image } from "@chakra-ui/react";
import { Container } from "../EmptyPicker/EmptyPicker.styles";
import { ErrorOverlay, RemoveButton } from "./PickedImage.styles";
import { PickedImageProps } from "./PickedImage.types";

export const PickedImage = ({ src, hasError, ...props }: PickedImageProps) => (
  <Container overflow="clip" position="relative">
    <RemoveButton hasError={hasError} {...props} />
    {hasError ? <ErrorOverlay /> : null}
    <Image src={src} w="100%" h="100%" />
  </Container>
);
