import { Footer } from "../../common";
import { FormBuilder } from "../FormBuilder/FormBuilder";
import { FormFailure } from "./FormStates/FailureState/FormFailure";
import { FormSuccess } from "./FormStates/SuccessState/FormSuccess";
import { STEPS_NUMBER } from "./ReviewForm.constants";
import { useReviewForm } from "./ReviewForm.hooks";
import { Container, MainContainer } from "./ReviewForm.styles";

export const ReviewForm = () => {
  const { states, actions } = useReviewForm();
  const { formSubmitState, activeForm, activeStepIndex } = states;
  const { handleSubmit, handleBack, handleYes, handleUpdateWatchName } = actions;

  return activeForm ? (
    <MainContainer>
      <Container>
        {formSubmitState === undefined ? (
          <FormBuilder
            activeStepIndex={activeStepIndex}
            onUpdateWatchName={handleUpdateWatchName}
            onSubmit={handleSubmit}
            steps={STEPS_NUMBER}
            onBack={handleBack}
            {...activeForm}
          />
        ) : formSubmitState === true ? (
          <FormSuccess />
        ) : (
          <FormFailure onYes={handleYes} />
        )}
      </Container>
      <Footer />
    </MainContainer>
  ) : null;
};
